@use 'src/styles/variables' as variables;
@use "~@amzn/sss-website-theme/dist/theme/index.module.scss" as theme;
@use 'src/styles/utility' as utils;

.header {
  @extend .appContainer;
  background-color: theme.$grey-secondary;
  display: flex;
  min-height: variables.$headerMinHeight;
  border-bottom: 1px solid rgba(theme.$green-primary, 0.15);

  @include utils.device-view {
    min-height: variables.$headerMinHeightDevice;
  }

  &.expanded {
    background-color: theme.$green-primary;
    border-bottom: 1px solid rgba(theme.$grey-secondary, 0.15);
  }

  &.dark {
    color: theme.$white;
    background-color: theme.$slate-dark;
    border-bottom: 1px solid rgba(theme.$white, 0.15);
  }

  .navigation {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;

    .listContainer {
      align-items: center;
      display: flex;
      flex-grow: 1;
      flex-basis: 100%;
      gap: variables.$navItemGap;
      list-style: none;
      margin: 0 variables.$headerSpacingLeftRight;

      @include utils.device-view {
        gap: variables.$navItemGapDevice;
        margin: 0 variables.$headerSpacingLeftRightDevice;
      }

      @include utils.device-view-small {
        gap: variables.$navItemGapDeviceSmall;
      }
    }
  }

  .subNavContainer {
    background-color: theme.$green-primary;
    border-top: 1px solid rgba(theme.$grey-secondary, 0.15);
    position: absolute;
    top: calc(#{variables.$headerMinHeight});
    left: 0;
    width: 100%;
    height: variables.$subNavMinHeight;
    border-bottom-left-radius: theme.$border-radius-medium;
    border-bottom-right-radius: theme.$border-radius-medium;
    font-size: 24px;
    line-height: 32px;
    color: theme.$grey-secondary !important;
    display: flex;
    padding-top: 20px;

    &.dark {
      background-color: theme.$slate-dark;
    }

    .subNavListContainer {
      grid-gap: variables.$subNavItemGap;
      grid-auto-flow: column;
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(3, 1fr);
      margin: 60px variables.$headerSpacingLeftRight;
      width: 60%;
      height: 60%;
    }

    .subNavImage {
      height: 243px;
      margin: 60px 60px 60px auto;
      border-radius: 32px;
      opacity: 0.95;
      object-fit: cover;
      max-width: theme.fluid-size(350px, 450px, theme.$smallDesktop, theme.$mediumDesktop);
    }
  }

  .deviceListContainer {
    background-color: theme.$green-primary;
    position: absolute;
    top: calc(#{variables.$headerMinHeightDevice});
    left: 0;
    width: 100%;
    height: calc(100vh - #{variables.$headerMinHeightDevice});
    overflow-y: scroll;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: variables.$navItemGapDevice;
    box-sizing: border-box;
    padding: variables.$headerSpacingTopBottomDevice variables.$headerSpacingLeftRightDevice
             calc(#{variables.$headerSpacingTopBottomDevice} + 48px);

    &.dark {
      background-color: theme.$slate-dark;
    }

    &.deviceNavListContainer {
      & > li {
        box-sizing: border-box;

        &:last-child {
          margin-top: auto;

          > a {
            width: 100%;
          }
        }

        &:not(:last-child) {
          height: 56px;
          border-bottom: 1px solid rgba(theme.$white, 0.2);

          > a {
            display: block;
            width: 100%;
          }
        }
      }
    }

    // Accounts for Safari iOS toolbar height
    @supports (height: 100dvh) {
      .deviceListContainer {
        height: calc(100dvh - #{variables.$headerMinHeightDevice});
      }
    }

    &.deviceSubNavListContainer {
      gap: variables.$navItemGap;

      & > li {
        &:last-child {
          margin-top: auto;

          > a {
            width: 100%;
          }
        }

        &:not(:last-child) {
          a {
            font-size: variables.$deviceSubNavFontSize;
          }
        }
      }
    }
  }
}
