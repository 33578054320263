@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/utility' as utils;
@use 'src/styles/variables' as variables;

.parent {
  background: none;
  border: none;
  color: theme.$green-primary;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  font-family: 'Amazon Ember', Helvetica, Arial, sans-serif;

  &.dark {
    color: theme.$white;

    &:hover {
      color: theme.$green-secondary;
    }
  }

  &.buttonSecondary {
    color: theme.$green-secondary;
  }

  &.buttonTertiary {
    color: theme.$grey-secondary;
    font-size: variables.$deviceNavFontSize;
    display: flex;
    width: 100%;
  }

  &.buttonQuaternary {
    color: theme.$green-secondary;
    font-size: variables.$deviceSubNavFontSize;
    display: flex;
    align-items: center;
  }
}

.parentIcon {
  @extend .icon;
  transform: translateY(2px);

  &.dark {
    path {
      fill: theme.$white;
    }
  }

  &.iconSecondary {
    @extend .iconSecondary;
  }

  &.iconTertiary {
    @extend .iconSecondary;
    height: variables.$headerIconSize;
    width: variables.$headerIconSize;
    margin-left: auto;

    path {
      fill: theme.$grey-secondary;
    }
  }

  &.iconQuaternary {
    @extend .iconSecondary;
    height: variables.$headerIconSize;
    width: variables.$headerIconSize;
    transform: translateX(-25%);

    path {
      fill: theme.$green-secondary;
    }
  }
}
