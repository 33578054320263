@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/variables' as variables;

.icon {
  path {
    fill: theme.$green-primary;
  }
  height: 16px;
  width: 17px;
}

.iconSecondary {
  @extend .icon;
  path {
    fill: theme.$green-secondary;
  }
}

.headerIconButton {
  all: unset;
  cursor: pointer;
  height: variables.$headerButtonSize;
  width: variables.$headerButtonSize;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    @extend .icon;

    width: variables.$headerIconSize !important;
    height: variables.$headerIconSize !important;
  }
}

.appContainer {
  font-size: 16px;
  line-height: 24px;
}

.noWrap {
  white-space: nowrap;
}

@mixin device-view-small {
  @media (max-width: variables.$deviceSmallMaxWidth) { @content; }
}

@mixin device-view {
  @media (max-width: variables.$deviceMaxWidth) { @content; }
}

@mixin desktop-view-up {
  @media (min-width: variables.$desktopMinWidth) { @content; }
}

@mixin desktop-view-large-up {
  @media (min-width: variables.$desktopLargeMinWidth) { @content; }
}

@mixin desktop-view-extra-large-up {
  @media (min-width: variables.$desktopExtraLargeMinWidth) { @content; }
}
