@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/utility' as utils;
@use 'src/styles/variables' as variables;

$dropdownButtonWidth: 184px;
$dropdownButtonHeight: 64px; 
$dropdownButtonBorderRadius: 35px; 
$mobileDropdownPaddingLeft: 25px;

.localePicker {
  @extend .headerIconButton;

  height: $dropdownButtonHeight;
  width: $dropdownButtonWidth;
  background-color: theme.$grey-tertiary;
  border-radius: $dropdownButtonBorderRadius;

  &:hover {
    background-color: theme.$green-primary;

    .localeLabel {
      color: theme.$green-secondary;

      &.secondaryLabel {
        color: theme.$green-primary;
      }
    }

    path {
      fill: theme.$green-secondary;
    }
  }

  &.secondaryLocalePicker {
    background-color: rgba(theme.$grey-tertiary, 0.2);

    &:hover {
      background-color: theme.$green-secondary;

      path {
        fill: theme.$green-primary;
      }
    }
  }

  .localeLabel {
    margin-right: 20px;
    color: theme.$green-primary;

    &.secondaryLabel {
      color: theme.$green-secondary;
    }
  }

  .localePickerIcon {
    @extend .icon;
    transform: translateY(2px);

    &.secondaryIcon {
      @extend .iconSecondary;
    }
  }
}

// CSS to customize native <select> button for mobile views
.mobileSelectWrapper {
  position: relative;

  // Mobile dropdown customized
  select {
    @extend .headerIconButton;
    height: $dropdownButtonHeight;
    width: $dropdownButtonWidth - $mobileDropdownPaddingLeft;
    background-color: rgba(theme.$grey-tertiary, 0.2);
    border-radius: $dropdownButtonBorderRadius;
    color: theme.$green-secondary;
    appearance: none;
    border: none;
    cursor: pointer;
    padding-left: $mobileDropdownPaddingLeft;
  }

  // Mobile dropdown chevron
  &:after {
    display: inline-block;
    position: absolute;
    border-style: solid;
    content: '';
    
    border-width: 0.15em 0.15em 0 0;
    height: 0.40em;
    width: 0.40em;
    top: 24px;
    left: $dropdownButtonWidth - 44px;

    color: theme.$green-secondary;
    transform: rotate(135deg);
  }
}

.dropdown {
  display: none;
  position: absolute;
  width: 136px;
  max-height: 300px;
  margin-top: 30px;
  padding: 20px 24px;
  z-index: 1;

  background-color: theme.$grey-tertiary;
  box-shadow: 0px 4px 24px 0px rgba(0,0,0,0.1);
  border-radius: 32px;
  overflow-y: scroll;

  &.show {
    display: block;
  }
  
  .dropdownOption {
    color: theme.$green-primary;
    margin-bottom: 12px;

    &:hover {
      font-weight: bold;
      cursor: pointer;
    }

    .checkContainer {
      float: right;
      background-color: theme.$green-secondary;
      width: 16px;
      height: 16px;
      border-radius: 12px;
      margin-top: 3px;

      .checkIcon {
        display: block;
        margin: 4px 3px;
      }
    }
  }
}