@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/utility' as utils;
@use 'src/styles/variables' as variables;

.link {
  color: theme.$green-primary !important;
  text-decoration: none;
  line-height: 24px;

  &.dark {
    color: theme.$white !important;

    &:hover {
      color: theme.$green-secondary !important;
    }
  }

  &.secondary {
    &:not(.dark) {
      color: theme.$green-secondary !important;
    }
  }

  &.tertiary {
    color: theme.$grey-secondary !important;
    display: flex;

    &:hover {
      color: theme.$green-secondary !important;
    }
  }

  &.quaternary {
    color: theme.$grey-secondary !important;
    font-size: variables.$deviceNavFontSize;
  }
}

.externalLinkIcon {
  @extend .iconSecondary;
}

.activeLinkIcon {
  @extend .icon;
  width: variables.$headerIconSize;
  height: variables.$headerIconSize;
  transform: translateY(2px);

  &.xcm {
    transform: translateY(-3px) !important;
  }

  path {
    fill: theme.$green-secondary;
  }
}


