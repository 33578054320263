@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/utility' as utils;
@use 'src/styles/variables' as variables;

.listItem {
  position: relative;

  &.footer {
    @include utils.device-view {
      flex-basis: 100%;
    }
  }

  &.rightAlign {
    margin-right: auto;
  }

  .activeIndicator {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: #{variables.$activeItemBottomBorderWidth} solid #{theme.$green-primary};
    transform: translateY(calc(#{variables.$headerMinHeight * 0.5} - #{variables.$activeItemBottomBorderWidth * 0.5}));

    @include utils.device-view {
      transform: translateY(calc(#{variables.$headerMinHeightDevice * 0.5} - #{variables.$activeItemBottomBorderWidth * 0.5}));
    }

    &.dark {
      border-bottom: #{variables.$activeItemBottomBorderWidth} solid #{theme.$green-secondary};
    }

    &.secondary {
      &:not(.dark) {
        border-bottom: #{variables.$activeItemBottomBorderWidth} solid #{theme.$green-secondary};
      }
    }

    &.logo {
      width: calc(100% - #{variables.$logoMarginRight});
    }
  }

  &.copyRight {
    @include utils.device-view {
      border-top: 1px solid rgba(theme.$white, 0.15);
      padding-top: 36px;
      order: 1; // Position after any other non-ordered elements
    }
  }

  &.hamburgerButton, &.closeButton {
    @include utils.desktop-view-up {
      display: none;
    }
  }

  &.closeButton {
    display: none;

    &.showCloseButton {
      display: initial;
    }
  }

  &.deviceSuppressed {
    @include utils.device-view {
      display: none;
    }
  }

  &.sustSiteLinkInHeader {
    // TODO: Remove launch code: https://taskei.amazon.dev/tasks/ASX-367
    &:not(.isNewBranding) {
      display: none;
      @include utils.desktop-view-extra-large-up {
        display: initial;
      }
    }

    display: initial;
  }

  &.subNavTitle {
    position: absolute;
    top: 24px;
    font-size: 16px;
  }
}
