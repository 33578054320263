@use 'src/styles/variables' as variables;
@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/utility' as utils;

.footer {
  @extend .appContainer;
  background-color: theme.$green-primary;
  display: flex;
  min-height: variables.$footerMinHeight;
  box-sizing: border-box;
  padding: variables.$footerVerticalPadding 0;

  .navigation {
    display: flex;
    flex: 1;

    .listContainer {
      align-items: center;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      list-style: none;
      margin: 0 variables.$footerSpacingLeftRight;

      @include utils.desktop-view-up {
        column-gap: variables.$footerItemColumnGapDesktop;
        row-gap: variables.$footerItemRowGapDesktop;
        align-items: center;
      }

      @include utils.desktop-view-large-up {
        column-gap: variables.$footerItemGapDesktopLarge;
      }

      @include utils.device-view {
        gap: variables.$footerItemGapDevice;
        margin: variables.$footerSpacingTopBottomDevice variables.$footerSpacingLeftRightDevice;
      }
    }
  }
}
