@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use '~@amzn/sss-website-theme/dist/theme/text.module.scss' as themeText;
@use 'src/styles/variables' as variables;
@use 'src/styles/utility' as utils;
@import '../../../../index.scss';

.headingLink {
  color: theme.$green-primary;
  text-decoration: none;

  &.secondary {
    color: theme.$white;
  }

  &.dark {
    color: theme.$white;
  }
}

.heading {
  font-family: themeText.$emberDisplayBold;
  font-size: 16px !important;
  letter-spacing: 0.02em !important;
  line-height: 1.2em !important;

  @include utils.desktop-view-up{
    font-size: 28px !important;
  }
}