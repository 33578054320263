@use 'src/styles/utility' as utils;
@use 'src/styles/variables' as variables;

$amz-logo-top-margin: 12px; 

.logo {
  height: variables.$logoHeight;

  @include utils.device-view {
    height: variables.$logoHeightDevice;
  }

  @include utils.device-view-small {
    height: variables.$logoHeightDeviceSmall;
  }
}

.amzLogo {
  margin-top: $amz-logo-top-margin;
}

.link {
  display: flex;
  margin-right: variables.$logoMarginRight;

  @include utils.device-view {
    margin-right: 0;
  }
}
