// Header
$headerMinHeight: 96px;
$headerMinHeightDevice: 72px;
$headerSpacingLeftRight: 48px;
$headerSpacingLeftRightDevice: 24px;
$headerSpacingTopBottomDevice: 24px;
$activeItemBottomBorderWidth: 2px;
$logoHeight: 32px;
$logoHeightDevice: 26px;
$logoHeightDeviceSmall: 24px;
$logoMarginRight: 48px;
$headerButtonSize: 56px;
$headerIconSize: 24px;

// SubNav
$subNavMinHeight: 357px;
$subNavItemGap: 24px;

// Device Nav
$deviceNavFontSize: 22px;

// Device SubNav
$deviceSubNavFontSize: 18px;

// Footer
$footerMinHeight: 72px;
$footerSpacingLeftRight: 48px;
$footerSpacingLeftRightDevice: 24px;
$footerSpacingTopBottomDevice: 48px;
$footerItemGapDesktopLarge: 65px;
$footerItemColumnGapDesktop: 48px;
$footerItemRowGapDesktop: 16px;
$footerItemGapDevice: 16px;
$footerVerticalPadding: 24px;

// Common
$navItemGap: 40px;
$navItemGapDevice: 24px;
$navItemGapDeviceSmall: 18px;
$deviceSmallMaxWidth: 429px;
$deviceMaxWidth: 1279px;
$desktopMinWidth: 1280px;
$desktopLargeMinWidth: 1440px;
$desktopExtraLargeMinWidth: 1680px;
