@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/utility' as utils;
@use 'src/styles/variables' as variables;

.hamburgerButton {
  @extend .headerIconButton;
  height: variables.$headerIconSize;
  width: variables.$headerIconSize;

  path {
    fill: theme.$green-primary;
  }

  &.dark {
    path {
      fill: theme.$white;
    }
  }
}
