@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/utility' as utils;
@use 'src/styles/variables' as variables;

.searchLink {
  @extend .headerIconButton;
  background-color: theme.$grey-tertiary;
  border-radius: 35px;

  &.dark {
    background-color: rgba(theme.$grey-tertiary, 0.05);

    &:hover {
      background-color: theme.$green-secondary;
      path {
        fill: theme.$green-primary !important;
      }
    }
  }

  @include utils.device-view {
    background-color: transparent;
    width: variables.$headerIconSize;
    height: variables.$headerIconSize;
  }

  &:hover {
    background-color: theme.$green-primary;

    @include utils.device-view {
      background-color: transparent;
    }

    @include utils.desktop-view-up {
      path {
        fill: theme.$green-secondary;
      }
    }
  }

  &.linkSecondary {
    &:not(.dark) {
      background-color: rgba(theme.$grey-tertiary, 0.2);

      &:hover {
        background-color: theme.$green-secondary;
        path {
          fill: theme.$green-primary;
        }
      }
    }
  }

  .searchIcon {
    &.dark {
      path {
        fill: theme.$white;
      }
    }

    &.iconSecondary {
      @extend .iconSecondary;
    }
  }
}
